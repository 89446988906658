import {Icon, SwipeItem, Swipe, Image} from 'vant'
export default {
  components:{
    'van-icon': Icon,
    'van-swipe-item': SwipeItem,
    'van-swipe': Swipe,
    'van-image': Image,
  },
  data () {
    return {
      iconNum:0,
      params:{
        token:this.$global.token,

      },
      apis:{
        findCommodity:'/seller/find',//商家详情
        queryCommodity:'/commodity/queryCommodity'
      },
      isLoading: false,
      value3: 'a',
      list: [],
      bizType:3,
      loading: false,
      finished: false,
      content:{},//内容
    }
  },
  created () {
    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
    this.params.sellerId =this.$route.query.seller
    this.params.token=this.$global.token;
    this.allList();
    this.init()
  },



  methods:{
    praise(){
      this.init();
    },
    collect(){
      this.init();
    },
    revert(){//返回安卓原生页面或者h5页面
      this.$router.go(-1)


    },
    init() {
      const me = this;
      me.$get({
        url:me.apis.findCommodity,
        params:me.params
      }).then((rep)=>{
        me.content = rep
        // me.allList()
      })
    },
    callphone(){//拨打电话
      window.location.href = 'tel://' + this.content.telephone
    },
    allList(){
      const me = this;
      me.$get({
        url:me.apis.queryCommodity,
        params:{
          sellerId:me.params.sellerId
        }

      }).then((rep)=>{
        me.list = rep.data
      })
    },
    breakFunc(obj){//详情页面参数改变
      this.$router.push(`/mallDetails/?commodityId=${obj.commodityId}`)
    },

    onChange (index) {
      this.iconNum = index
    },
  }

}
